<template>
  <div class="calendar font-sm">
    <div class="top clearfix">
      <div class="subject pointer" title="연/월 선택">
        <select v-model="state.display.year" @change="draw()">
          <option :value="y" v-for="y in years" :key="y">{{ y }}년</option>
        </select>
        <select class="ml-2" v-model="state.display.month" @change="draw()">
          <option :value="i - 1" v-for="i in 12" :key="i">{{ i }}월</option>
        </select>
      </div>
      <div class="actions">
        <span class="today">
          <i class="today fa fa-calendar-check-o" title="이번 달로 이동" @click="turnToday()"></i>
        </span>
        <span class="prev move">
          <i class=" fa fa-angle-left" aria-hidden="true" title="이전 달" @click="turn(-1)"></i>
        </span>
        <span class="next move">
          <i class="fa fa-angle-right" aria-hidden="true" title="다음 달" @click="turn(1)"></i>
        </span>
      </div>
    </div>
    <div class="header clearfix">
      <span v-for="(w, idx) in ['일', '월', '화', '수', '목', '금','토']" :key="idx">{{ w }}</span>
    </div>
    <div class="dates clearfix">
        <span :class="{gray: d.gray, active: d.active, today: d.today}" v-for="(d, idx) in state.display.dates" :key="idx" @click="select(d)">
          <span>
            <span :title="d.today ? '오늘' : ''">{{ d.text }}</span>
          </span>
        </span>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import lib from "@/scripts/lib";

function Component(initialize) {
  this.name = "modalCalendar";
  this.initialize = initialize;
  this.checkDuplicatedName = true;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "sm"});

      for (let i = 1900; i <= new Date().getFullYear() + 7; i += 1) {
        years.push(i);
      }

      const selDate = new Date();

      if (modalParams.format === "yyyyMMdd" && modalParams.value) {
        const dateArr = [modalParams.value.substr(0, 4), modalParams.value.substr(4, 2), modalParams.value.substr(6, 2)];
        selDate.setFullYear(Number(dateArr[0]));
        selDate.setMonth(Number(dateArr[1]) - 1);
        selDate.setDate(Number(dateArr[2]));
        state.selDate = selDate;
      } else if (modalParams.format === "yyyy-MM-dd" && modalParams.value) {
        const dateArr = [modalParams.value.substr(0, 4), modalParams.value.substr(5, 2), modalParams.value.substr(8, 2)];
        selDate.setFullYear(Number(dateArr[0]));
        selDate.setMonth(Number(dateArr[1]) - 1);
        selDate.setDate(Number(dateArr[2]));
        state.selDate = selDate;
      }

      state.display.year = selDate.getFullYear();
      state.display.month = selDate.getMonth();
      draw();
    });

    const state = reactive({
      selDate: new Date(),
      years: [],
      months: [],
      display: {
        year: 0,
        month: 0,
        dates: []
      }
    });

    const modalParams = store.getters.modalParams(component);
    const years = [];

    const select = (d) => {
      const formattedDate = d.value ? lib.getDateFormat(d.value, modalParams.format || "yyyyMMdd") : "";
      store.commit("closeModal", {
        name: component.name,
        onClose(modal) {
          store.dispatch("callback", {
            modal,
            params: {
              value: formattedDate
            }
          });
        }
      });
    };

    const turn = (num) => {
      state.display.month += num;

      if (state.display.month > 11) {
        state.display.year += 1;
        state.display.month = 0;
      } else if (state.display.month < 0) {
        state.display.year -= 1;
        state.display.month = 11;
      }

      draw();
    };

    const turnToday = () => {
      const curDate = new Date();
      state.display.year = curDate.getFullYear();
      state.display.month = curDate.getMonth();
      draw();
    };

    const getFirstDayOfMonth = (d) => {
      const date = new Date(d.getTime());
      date.setDate(1);
      return date.getDay();
    };

    const getLastDayOfMonth = (d) => {
      const date = new Date(d.getTime());
      const lastDateOfMonth = getLastDateOfMonth(date);
      date.setDate(lastDateOfMonth);
      return date.getDay();
    };

    const getLastDateOfPrevMonth = (d) => {
      const date = new Date(d.getTime());
      date.setDate(1);
      date.setDate(date.getDate() - 1);
      return date.getDate();
    };

    const getLastDateOfMonth = (d) => {
      const date = new Date(d.getTime());
      date.setDate(1);
      date.setMonth(date.getMonth() + 1);
      date.setDate(date.getDate() - 1);
      return date.getDate();
    };

    const draw = () => {
      const date = new Date(state.display.year, state.display.month, 1, 0, 0, 0, 0);
      const firstDayOfMonth = getFirstDayOfMonth(date);
      const lastDateOfPrevMonth = getLastDateOfPrevMonth(date);
      const lastDateOfMonth = getLastDateOfMonth(date);
      const lastDayOfMonth = getLastDayOfMonth(date);

      state.display.dates = [];

      for (let i = 0; i < firstDayOfMonth; i += 1) {
        const ctxDate = new Date(date.getTime());
        ctxDate.setDate(ctxDate.getDate() - i - 1);

        state.display.dates.unshift({
          text: lastDateOfPrevMonth - i,
          value: ctxDate,
          today: false,
          active: false,
          gray: true,
        });
      }

      for (let i = 0; i < lastDateOfMonth; i += 1) {
        const curDate = new Date();
        const ctxDate = new Date(date.getTime());
        ctxDate.setDate(ctxDate.getDate() + i);

        const today = curDate.getFullYear() === ctxDate.getFullYear()
            && curDate.getMonth() === ctxDate.getMonth()
            && curDate.getDate() === ctxDate.getDate();

        const active = state.selDate
            ? state.selDate.getFullYear() === ctxDate.getFullYear()
            && state.selDate.getMonth() === ctxDate.getMonth()
            && state.selDate.getDate() === ctxDate.getDate()
            : false;

        state.display.dates.push({
          text: i + 1,
          value: ctxDate,
          today: today,
          active: active,
          gray: false
        });
      }

      if (lastDayOfMonth < 7) {
        for (let i = 0; i < 7 - lastDayOfMonth - 1; i += 1) {
          const ctxDate = new Date(date.getTime());
          ctxDate.setDate(lastDateOfMonth);
          ctxDate.setDate(ctxDate.getDate() + i + 1);

          state.display.dates.push({
            text: i + 1,
            value: ctxDate,
            today: false,
            active: false,
            gray: true
          });
        }
      }
    };

    return {component, state, years, draw, select, turn, turnToday};
  }
});
</script>

<style lang="scss" scoped>
.calendar {
  background: #fff;
  border: $px1 solid #eee;
  text-align: center;
  border-radius: $px4;
  width: 100%;
  z-index: 8;

  .top {
    font-weight: 500;
    background: $colorBackground;
    border-bottom: $px1 solid #eee;
    position: relative;
    text-align: left;
    padding: $px5;

    .subject {
      float: left;
      padding: $px10;

      select {
        border: $px1 solid $colorBorder;
        border-radius: 4px;
        margin-right: $px1;
        padding: $px10 $px30 $px10 $px10;
      }
    }

    .actions {
      float: right;
      padding-top: $px8;
      padding-right: $px6;
      font-size: 0;

      > span {
        display: inline-block;

        i {
          padding: $px11 $px8;
          cursor: pointer;
          opacity: 0.8;
          transition: opacity 0.18s;
          vertical-align: middle;
          font-size: $px16;
        }

        &.today {
          position: relative;

          &:after {
            content: "이번 달로 이동";
            position: absolute;
            top: $px-8;
            left: $px-66;
            display: inline-block;
            padding: $px3 0;
            font-size: $px10;
            width: $px71;
            background: $colorDark;
            text-align: center;
            color: #fff;
            white-space: nowrap;
            border-radius: $px4 $px4 0 $px4;
          }
        }

        &.move > i {
          font-size: $px24;
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .title {
    padding: $px10;

    a {
      float: right;
      font-size: 0.75rem;
      margin-top: $px-2;
      padding: $px2 $px5;
    }
  }

  ul {
    li {
      float: left;
      width: 25%;
      padding: $px5;

      div {
        padding: $px5 0;
        border: $px1 solid transparent;
        border-radius: $px4;
        transition: border-color 0.18s, background-color 0.18s;
        position: relative;

        &:hover {
          border-color: #eee;
          background: $colorBackground;
        }
      }

      &.active {
        div {
          background: $colorPoint;
          border-color: $colorPoint;
          color: #fff;

          &:hover {
            background: $colorPointActive;
            border-color: $colorPointActive;
          }
        }
      }

      &.today {
        > div:after {
          content: " ";
          display: inline-block;
          width: $px3;
          height: $px3;
          background: $colorPoint;
          border-radius: 50%;
          position: absolute;
          top: $px4;
          right: $px3;
        }

        &.active > div:after {
          background: #fff;
        }
      }
    }
  }

  .header, .dates {
    span {
      display: inline-block;
    }

    > span {
      float: left;
      width: calc(100% / 7 - 0.01px);
    }
  }

  .header {
    padding: $px10 $px5 0 $px5;

    > span {
      color: #767676;
      padding: $px9 0;
      border: $px1 solid transparent;
    }
  }

  .dates {
    padding: 0 $px5 $px4 $px5;

    > span {
      padding: 0 $px5;
      position: relative;
      margin: $px3 0;

      > span {
        padding: $px9 0;
        width: 100%;
        border: $px1 solid transparent;
        border-radius: $px2;
        cursor: pointer;
        transition: border-color 0.18s, background-color 0.18s;
        position: relative;
      }

      &:hover > span {
        background: $colorBackground;
        border-color: #eee;
      }

      &.today > span:after {
        content: " ";
        display: inline-block;
        width: $px3;
        height: $px3;
        background: $colorPoint;
        border-radius: 50%;
        position: absolute;
        top: $px5;
        right: $px5;
      }

      &.active {
        > span {
          background: $colorPoint;
          border-color: $colorPoint;
          color: #fff;
          font-weight: 500;

          &:hover {
            background: $colorPointActive;
            border-color: $colorPointActive;
          }
        }

        &.today > span:after {
          background: #fff;
        }
      }

      &.gray > span > span {
        opacity: 0.25;
      }
    }
  }
}
</style>